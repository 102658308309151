<!--
 * @Author: 张阳帅
 * @Date: 2024-09-25 17:52:37
 * @LastEditTime: 2024-11-21 16:11:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\patient\patientAdd.vue
-->
<template>
  <div class="gcjregister_page">
    <div class="page_one">
      <div class="one_item">
        <div class="item_left">
          就诊人类型：
        </div>
        <div class="item_right"> 
          <van-radio-group v-model="jzrlx" direction="horizontal">
            <van-radio name="1" checked-color="#1e80ff">
              成人
            </van-radio>
            <van-radio name="2" checked-color="#1e80ff">
              儿童(小于14岁)
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          姓名：
        </div>
        <div class="item_right"> 
          <input v-model="xm" class="right_name" placeholder="请输入姓名" maxlength="64">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          性别：
        </div>
        <div class="item_right"> 
          <van-radio-group v-model="xb" direction="horizontal">
            <van-radio name="1" checked-color="#1e80ff">
              男
            </van-radio>
            <van-radio name="2" checked-color="#1e80ff">
              女
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          证件类型：
        </div>
        <div class="item_right" @click="seleteZjlxAction">
          {{ zjlxName }}
          <!-- <van-icon name="arrow" size="23" /> -->
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          证件号：
        </div>
        <div class="item_right">
          <input v-model="zjh" class="right_name" placeholder="请输入证件号" @blur="setBirthdayData">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          出生日期：
        </div>
        <div class="item_right">
          <input v-model="csrq" class="right_name" placeholder="请选择出生日期" readonly @click="showDatePicker = true">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          手机号：
        </div>
        <div class="item_right">
          <input v-model="sjh" class="right_name" placeholder="请输入手机号" maxlength="11">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          与本人关系：
        </div>
        <div class="item_right" :class="gxName === '请选择与本人关系' ? 'item_right_empty':''" @click="seleteGxAction">
          {{ gxName }}
          <!-- <van-icon name="arrow" size="23" /> -->
        </div>
      </div>
    </div>
    <div v-if="jzrlx=='2'" class="page_two">
      <div class="one_item">
        <div class="item_left">
          监护人姓名：
        </div>
        <div class="item_right">
          <input v-model="jhrxm" class="right_name" placeholder="请输入姓名" maxlength="15">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          监护人证件类型：
        </div>
        <div class="item_right" @click="seleteJhrzjlxAction()">
          {{ jhrzjlxName }}
          <van-icon name="arrow" size="23" />
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          监护人证件号：
        </div>
        <div class="item_right">
          <input v-model="jhrzjh" class="right_name" placeholder="请输入监护人证件号">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          监护人手机号：
        </div>
        <div class="item_right">
          <input v-model="jhrsjh" class="right_name" placeholder="请输入监护人手机号" maxlength="11">
        </div>
      </div>
    </div>
    <div class="page_two_1">
      <div class="one_item">
        <div class="item_left">
          是否默认：
        </div>
        <div class="item_right">
          <van-switch v-model="sfmr" active-color="#1e80ff" inactive-color="#dcdee0" />
        </div>
      </div>
    </div>
    <div class="page_bottom">
      <div class="bottom_btn" @click="debounceSavePatientAction">
        保存
      </div>
      <!-- <div class="three_des">温馨提示:在线支付需全自费支付诊查费用</div> -->
    </div>
    <van-popup v-model="zjlxShow" position="bottom">
      <van-picker title="请选择证件类型" show-toolbar :columns="zjlxColumns" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>
    <van-popup v-model="jhrzjlxShow" position="bottom">
      <van-picker title="请选择证件类型" show-toolbar :columns="zjlxColumns" @confirm="onJhrConfirm" @cancel="onJhrCancel" />
    </van-popup>
    <van-popup v-model="gxShow" position="bottom">
      <van-picker title="请选择与本人关系" show-toolbar :columns="gxColumns" @confirm="onGxConfirm" @cancel="onGxCancel" />
    </van-popup>
    <van-popup v-model="showDatePicker" round position="bottom">
      <van-datetime-picker
        v-model="birthday"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        :min-date="minDate"
        :formatter="formatter"
        @confirm="onDatePickerConfirm"
        @cancel="showDatePicker = false"
      />
    </van-popup>
    <van-dialog v-model="showDialog" title="就诊人建档" show-cancel-button @confirm="onVerifyCode">
      <div v-if="zjlx !=='01'" class="dialog-content">
        <div class="dialog-des">
          新建就诊人，需要进行手机验证码校验，请在下方输入框输入手机验证码
        </div>
        <van-field
          v-model="verifyCode"
          maxlength="6"
          class="phone-field"
          placeholder="请输入验证码"
        >
          <template #button>
            <count-down-gjc
              :phone-num="phoneNum"
              @get-code-success="getCodeSuccess"
            />
          </template>
        </van-field>
      </div>
      <div v-else class="dialog-content">
        <div class="dialog-des">
          未查询到就诊人信息，是否建档？
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { gjcModel } from '../../../api/gjcApi'
import { formatTime, getBirth } from '../../../utils/cache'
import { isPhoneNum } from '@/utils/index'
import CountDownGjc from "../../../components/countdown/CountDownGjc.vue"
import { debounce } from "../../../utils/index"
import { Toast } from 'vant'
export default {
  components: {
      CountDownGjc
    },
    data(){
        return{
            id:'', //就诊人id
            jzrlx: '1', //就诊人类型： 1成人 2儿童
            xm:'', //姓名
            xb: '1', //性别： 1男 2女
            zjlx:'01', //证件类型
            zjlxName:'居民身份证',//证件类型名称
            zjlxShow:false, //证件类型弹窗
            zjlxColumns: [{text:'居民身份证',id:'01'},{text:'港澳居民来往内地通行证',id:'06'},{text:'台湾居民来往内地通行证',id:'07'}],
            zjh:'', //证件号
            csrq:'',//出生日期
            sjh:'', //手机号
            gx :'', //关系
            gxName:'请选择与本人关系',//与本人关系
            gxShow:false, //关系类型弹窗
            gxColumns: [{text:'本人',id:'1'},{text:'夫妻',id:'2'},{text:'父母',id:'3'},{text:'子女',id:'4'},{text:'其他',id:'99'}],
            jhrxm:'',//监护人姓名
            jhrzjlx:'01', //监护人证件类型
            jhrzjlxName:'居民身份证',//监护人证件类型名称
            jhrzjlxShow :false, //监护人证件类型弹窗
            jhrzjh:'', //监护人证件号
            jhrsjh:'', //监护人手机号
            sfmr: false, //是否默认 1是 0否
            showDatePicker:false,
            minDate: new Date(1930, 0, 1),
            maxDate: new Date(),
            birthday: new Date(),//弹窗出生日期
            showDialog:false,
            verifyCode:'',//验证码
            phoneNum:'',//手机号
            temPatient:{} //临时就诊人数据
        }
    },
    mounted() {},
    methods:{
      onShowDialog() {
        this.phoneNum = this.jzrlx === '1' ? this.sjh : this.jhrsjh
        this.verifyCode = ""
        this.showDialog = true
      },
      onVerifyCode() {
        if(this.zjlx !== '01') {
            gjcModel.verifySendCaptchaGjc({phone:this.phoneNum,captcha:this.verifyCode}).then((result) => {
              if(result.code === 200) {
                this.addPatientAction(this.temPatient)
              }
            })
        } else {
          this.addPatientAction(this.temPatient)
        }
      },
        getCodeSuccess() {},
        seleteZjlxAction() {
            this.zjlxShow = true
        },
        onConfirm(value) {
            //this.$toast(`当前值：${value}, 当前索引：${index}`)
            this.zjlxName = value.text
            this.zjlx = value.id
            this.zjlxShow = false
        },
        onCancel() {
            this.zjlxShow = false
        },
        seleteGxAction() {
            this.gxShow = true
        },
        onGxConfirm(value) {
            this.gxName = value.text
            this.gx = value.id
            this.gxShow = false
        },
        onGxCancel() {
            this.gxShow = false
        },
        onDatePickerConfirm(val) {
          val = formatTime(val)
          this.csrq = val
          this.birthday = new Date(getBirth(this.zjh))
          this.showDatePicker = false
        },
        setBirthdayData() {
            if(this.zjlx == '01') {
                this.csrq = getBirth(this.zjh)
                this.birthday = new Date(getBirth(this.zjh))
            }
        },
        seleteJhrzjlxAction(){
            this.jhrzjlxShow = true
        },
        onJhrConfirm(value) {
            this.jhrzjlxName = value.text
            this.jhrzjlx = value.id
            this.jhrzjlxShow = false
        },
        onJhrCancel() {
            this.jhrzjlxShow = false
        },
        // 点击保存 ①先查询His信息 ②添加就诊人
         debounceSavePatientAction:debounce(function(){
          this.savePatientAction()
        },500,true),
        savePatientAction() {
            if(!this.xm) {
                this.$toast('请输入姓名')
                return
            }
            // if(!this.validateChineseName(this.xm)) {
            //     this.$toast('请输入正确的姓名')
            //     return
            // }
            if(!this.zjlx) {
                this.$toast('请选择证件类型')
                return
            }
            if(!this.zjh) {
                this.$toast('请输入证件号')
                return
            }
            // if (this.zjlx === '01' && !this.validateIDCard(this.zjh)) {
            //      this.$toast('请输入正确的身份证号')
            //     return
            // }
            if(!this.sjh) {
                this.$toast('请输入手机号')
                return
            }
            if(!isPhoneNum(this.sjh)) {
                this.$toast('请输入正确的手机号')
                return
            }
            if(!this.gx) {
                this.$toast('请选择与本人关系')
                return
            }
            Toast.loading({
              mask: true,
              duration: 0,
              message: "加载中..."
            })
            const params = {
                jzrlx: this.jzrlx,
                xm: this.xm,
                xb: this.xb,
                zjlx:this.zjlx,
                zjh:this.zjh,
                csrq:this.csrq,
                sjh:this.sjh,
                gx:this.gx,
                sfmr:this.sfmr ? '1' : '0',
                jhrxm:this.jhrxm,
                jhrzjlx:this.jhrzjlx,
                jhrzjh:this.jhrzjh,
                jhrsjh:this.jhrsjh
            }
            gjcModel.getHisPatientData(params).then((result) => {
                // console.log('添加就诊人成功',result)
                if (result.code === 200) {
                  if (result.data?.cardList && result.data.cardList.length > 0) {
                     this.addPatientAction(result.data)
                  }else{
                    Toast.clear()
                    this.temPatient = result.data
                    this.onShowDialog()
                  }
                }
            }).catch((err) => {
                setTimeout(() => {
                   Toast.clear() 
                }, 1000) 
                console.log('查询His就诊人失败',err)
            })
        },
        addPatientAction(patient) {
            if(!this.xm) {
                this.$toast('请输入姓名')
                return
            }
            if(!this.zjlx) {
                this.$toast('请选择证件类型')
                return
            }
            if(!this.zjh) {
                this.$toast('请输入证件号')
                return
            }
            if(!this.sjh) {
                this.$toast('请输入手机号')
                return
            }
            if(this.sjh.length!=11) {
                this.$toast('请输入正确的手机号')
                return
            }
            if(!this.gx) {
                this.$toast('请选择与本人关系')
                return
            }
            const params = {
                jzrlx: this.jzrlx,
                xm: this.xm,
                xb: this.xb,
                zjlx:this.zjlx,
                zjh:this.zjh,
                csrq:this.csrq,
                sjh:this.sjh,
                gx:this.gx,
                sfmr:this.sfmr?'1':'0',
                jhrxm:this.jhrxm,
                jhrzjlx:this.jhrzjlx,
                jhrzjh:this.jhrzjh,
                jhrsjh:this.jhrsjh,
                cardList:patient.cardList
            }
            gjcModel.getAddPatientData(params).then(() => {
              Toast.clear()
                this.$toast('添加就诊人成功')
                setTimeout(() => {
                    this.$router.back()
                }, 1500)
            }).catch((err) => {
              setTimeout(() => {
                   Toast.clear() 
                }, 1000) 
                console.log('添加就诊人失败',err)
            })
        },
        // validateChineseName(name) {
        //     // 正则表达式：匹配一个或多个中文字符
        //     const regex = /^[\u4e00-\u9fa5]+$/
        //     // 使用正则表达式的test方法进行校验
        //     return regex.test(name)
        // },
        // validateIDCard(idCard) {
        //     // 正则表达式：匹配15位或18位身份证号码
        //     const regex = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/
        //     // 使用正则表达式的test方法进行校验
        //     return regex.test(idCard)
        // },
        formatter(type, val) {
          if (type === 'year') {
            return val + '年'
          }
          if (type === 'month') {
            return val + '月'
          }
          if (type === 'day') {
            return val + '日'
          }
          return val
        }
    }
}
</script>
<style scoped lang='less'>
.gcjregister_page{
    background-color: #f5f5f5;
    padding-bottom: calc(env(safe-area-inset-bottom) + 124px);
}
.page_one{
    background-color: white;
    border-bottom: 20px solid #f5f5f5;
}
.one_item{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #e1e1e1;
    .item_left{
        font-size: 30px;
        color: #333333;

        .item_docName{
            font-weight: bold;
            .item_docTitle{
                margin-left: 24px;
                font-size: 24px;
                color: #666;
                font-weight: normal;
            }
        }
        .item_deptName{
            font-size: 24px;
            color: #999999;
        }
    }
    .item_right{
        display: flex;
        font-size: 30px;
        color: #333;
        .right_name{
            border: 0;
            text-align: right;
        }
    }
    .item_right_empty{
      color: #B2B2B2 !important;
    }
}
.page_two{
    background-color: white;
    border-bottom: 20px solid #f5f5f5;

    .item_pa{
        display: flex;
        font-size: 32px;
        color: #999999;
    }
}

.page_two_1{
    background-color: white;
    border-bottom: 2px solid #f5f5f5;

    .item_pa{
        display: flex;
        font-size: 32px;
        color: #999999;
    }
}

.page_bottom{
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 32px calc(env(safe-area-inset-bottom) + 12px);
    .bottom_btn{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 86px;
      background-color: #1e80ff;
      border-radius: 43px;
      color: white;
      font-size: 32px;
      text-align: center;
    }

    .three_des{
        margin: 0 24px;
        text-align: center;
        font-size: 24px;
        color: #999;
    }
}

.dialog-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 32px;
  .dialog-des{
    font-size: 24px;
    color: #666;
    margin-bottom: 24px;
  }
}

.phone-field{
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}
    
</style>
